import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "color"
    }}>{`Color`}</h2>
    <p>{`Refer to the `}<a parentName="p" {...{
        "href": "/components/button/style"
      }}>{`button`}</a>{` for primary and secondary button styling in the transactional modal.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Color token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-container`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$ui-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-header__label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-header__heading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`text color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$text-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-close__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$icon-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-close:hover`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`background-color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$hover-ui`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Overlay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`color`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$overlay-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "typography"
    }}>{`Typography`}</h2>
    <p>{`Modal titles and labels should be set in sentence case. Keep all titles and labels concise and to the point. Modal labels are optional.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-size (px/rem)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Font-weight`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-header__label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 / 0.75`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$label-01`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-header__heading`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20 / 1.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$heading-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14 / 0.875`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Regular / 400`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$body-long-01`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "structure"
    }}>{`Structure`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Class`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`px / rem`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Spacing token`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-close`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 x 48`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-close__icon`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`height, width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 x 16`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-header__label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4 / 0.25`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-02`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding top, padding left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-header`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8 / 0.5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-03`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`75%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`padding-left`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16 / 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-05`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--modal-content`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`margin-bottom`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`48 / 3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`$spacing-09`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--primary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.bx--btn--secondary`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`width`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`–`}</td>
        </tr>
      </tbody>
    </table>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "88.04347826086956%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsSAAALEgHS3X78AAADY0lEQVQ4y7WSzW/bZBzHU+cySjsxqHYZO3Dgwt8wiVs8/oAhNnFivSHuiP+BA0zaATExJEY3bWvSJk3X5WVr6FrR5sXOuxPH744dv8VOUtbOsX88Tl+yVeM2Humr7+PH0kf++fmEQmidv/tr6L4sY+mugqVVFctqGpbtvSXoPKOoWIYVsbRlYlEA7B5AgAgtHfc7Wb+chX1w+9bs77J6ZU0zriY0PZLUzUjSMCOrai8S6ypvZEVRIyuGHokrKh7fJq5Fc8XLAWNlqzRzCpyjyfksx7c7XQWakgycbgCr6SBaNnSdAchBbAe6wyGIogrMXgOYfAPYnRqQueKNgFH5qxQ+BVK953PJdKfJNngQBHbMdjo+Q9O+qev+aDj0h45zlNHQdzjZ19N533hWHDtbVRBTu9cDhpTamwIfg3dus0TXuVYH6Bbl0hTltVGoet2jGg2vUat5TZR2u+XJFcqzMkXPyBRcJ1cG6ekRUE7tToGXTOX9FMfSDTRukWagzPFAshwQDAcVXgQSPbdUDdi+DR1RgTbRhhbRAq4pArFXm4xM5utT4IW7dy4u6XoxZfUPNizL3jCtwSTWmRyfPbH7gw3btpN9y40ZxrXJpZjGFHj+8UMMX41++kN+9/IjU//ovqYuPNB7C0G/vg/6gaouLPHCwppufPh9qXDp8+jDCwEDj8eOYH+8AyGFs4z3frw1+2e/eyXt2FfXrT6+blk4+gIceYgvSzIelbv4chC0jykKjjzEkYdfJLbJL5F/Ew9XXxBTDz/xyvMJkm03WQmqPA9VdCFlhgXOMEEd7YMyGIE6RNn/ByRZe8ND4m0ekq31udQm35BoCQSeHfMM4/Ms48uC4Cuy7HdF0Zd43pdlydcpxrfSBc/MFMaDXOVUm+7T17S5rcC5XKtZr9eQ+eXKq0q15pYrFZcgSdRV1GW3SVEuKwiuWKdddZMIcqi9qAKTzX8VMLhsYQqcX7538ZHaE/KvxrCFRttGo51kZ//lpHcPDmHv0IW/Xx7Azgi9Q78iP/bhidL7OmBsqL0p8OPf7sz+zDI3l3Xt2zXHvrlm9xdPkjjuuGUurprGYrxvTc6Sjv0N0ui7nzr0Z5MpOXYm9L+tSKkQAoAZByCcMPTwumWGk+Z/B2kVjvV6YeRfGI4dPOl/AZ+TFDr5zN/4AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for passive modal",
          "title": "Structure and spacing measurements for passive modal",
          "src": "/static/68486b1abe6ff324c321099c98c7a451/fb070/modal-style-1.png",
          "srcSet": ["/static/68486b1abe6ff324c321099c98c7a451/d6747/modal-style-1.png 288w", "/static/68486b1abe6ff324c321099c98c7a451/09548/modal-style-1.png 576w", "/static/68486b1abe6ff324c321099c98c7a451/fb070/modal-style-1.png 1152w", "/static/68486b1abe6ff324c321099c98c7a451/c3e47/modal-style-1.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for a passive modal | px / rem
    </Caption>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.83152173913043%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAACo0lEQVQoz6WSS0wTURSGr70R0AKKUiIqNTwSCMbESOLKtUswPhIXaHjZohB3GuOWxJV2ZaTGoLJhwcIYozQgpKVETWhLkXY65dF2OtN26JRKW2grM9M5zgxFcat/8uU/meR+OXdyEVLS3oHOu+0IABQ0HiaG1+JJmQ28up/1DbzGJfEKs45XVxm86A9hyINGOadkty9dRPtyAP1b/pzbs1e9HTk2GggO2cLMc3uEffaFjZu+ridU5qKsaZaJqtgjMZM9HDHNBmnTXIh5alkOvBjz+C4ojnHCr0FL6awqvAxwyOoNsclkCqIcB+lsDpLpjAovwV8RUjnILoYgt0RB3kNDwOq6oThCtgWMhosb2p3vj3y20T5qmQGaCf9kaJqnKIoPy8SiUZ6Lx3k2FuMz21t8Jszy3LSTT1gXdhK2RaCnHR2Kg5l2YDQibKvCNwBldoJcDpB+8HmJwgpJAkkQ4Cd8QHqV9oPX44MgRQNLhiA25ZLYmYUCZ1uC4KTjirrhlCzc+4fVM+O6jz8SK9+zeXCmtwrOdEaa30xJzlRGcmW2pfkkK7mTjOTaoCU3R0tEIqJQIDfj4IhRqtDFhjF6+SGnCjvH4OjQqNX/+PUreGg28w/MZlFuUelHw8Pik3eUeH9SEAcncuKgJS8OWHLCgCXPD0zuwJ1PGfXKhoktjOpOt6nCVmQs0Z9oYmprdFCh1f6mUunDWtC2uwEZ5et0ytwuckumW/kmXN/dSsCooU6nzk1tVbj+VO3NxpP6u836BsM5fdO9Fn2jsVlfb2g502gsu/atVxZ2lRrErhKDtJ/eg/2CXnHIXXyPZ9H/p0csDrXlu91ahlBNuQZVV2CkkymRX//xUox1lRhVl2N01YVRH2DULWLUI3ePJM/F7itu1iugX1hLyN9ZGZ0PAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for transactional modal elements",
          "title": "Structure and spacing measurements for transactional modal elements",
          "src": "/static/1d8354312717aeb05b86cda06e9e8b7b/fb070/modal-style-2.png",
          "srcSet": ["/static/1d8354312717aeb05b86cda06e9e8b7b/d6747/modal-style-2.png 288w", "/static/1d8354312717aeb05b86cda06e9e8b7b/09548/modal-style-2.png 576w", "/static/1d8354312717aeb05b86cda06e9e8b7b/fb070/modal-style-2.png 1152w", "/static/1d8354312717aeb05b86cda06e9e8b7b/c3e47/modal-style-2.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for a transactional modal | px / rem
    </Caption>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.13043478260869%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACz0lEQVQ4y62TS0wTQRjHh91GIjEQQCTo1ZAYEzHRQyn0AQq9GL149kCiaEDAGBsxRi7G+AjSBDx4JmriwYuUAPIQeShQHgKF8mjpti5sdyll+6TT7n7OLo0a9aZf8sv/m8P+8s3MDip89hih200IABQynAxLu7YEmhF2aA//J4wQpN1ejnat+uivy26afEOhdCmO/1U/pKig9SmN6mqzCl535r3xeB6O+dj2MZZrm/BvW79wvHWM3foNzjrCsNZhl7dtZMPX1u1c73g1v3Rccb11ODNQjc2WoSwuA2SNOD28KIYhIIogRqMQTSTgb5UKRCA664b4ghd27KvgHpouVxye4VkKSel994OQ1fuRcbIbm8AL/gTLspj3+3E4FPqJKOJwNIKDawzm++1YGJrBW4PTmPkwqVUcvv4pCnWkhY+edOYubgprUjIF8cSelMAYUpIEyVSSkFLBySSkQIawlwNuYFrmh+eAHZqRPQP2UsXhHbRT6N3Suio8AnBgiePXkuEI7AYCUmgnCDExBLFQKJ1km2IEYrE47K74gOuZlP3908D2TckbPROqkOmZpFBvesLiT+/z+oKB9dVEEhYiUYkAC0SuMK8gboNj1w8OUQAHvwmLPkZ2sF6Y83nkSY9LFdoZN4WC3/aFupfLhW3dMyvWnm5otdn2CPg5odXWha1d3bh9iMMtn+P4wWgEt4xHSR9LEPD98Uji7mhIPcPmsTCFzpmuqLdck/8i02y8KJirzkO5TgflZQploC8rJWmC4hs85FgADjaS36GJ8Gtel/WKgySFzNpT6oSl5cc0VXpdbbW+otlsqLhzQV95z2yotJj1JkuVsdpSdNV5K7sJGvMapMbcBvl3ihQHSXU4lKn996dyqC797E4a922HDSWoxKijzxBOG0ppdOkodcKkpc+SvsRkoPOvuejsBtDk1EuanJugya4HNVXq92WkR98BQkkcunPhVMYAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Structure and spacing measurements for input modal elements",
          "title": "Structure and spacing measurements for input modal elements",
          "src": "/static/071d787ac55a8420707e72bed0520fb4/fb070/modal-style-4.png",
          "srcSet": ["/static/071d787ac55a8420707e72bed0520fb4/d6747/modal-style-4.png 288w", "/static/071d787ac55a8420707e72bed0520fb4/09548/modal-style-4.png 576w", "/static/071d787ac55a8420707e72bed0520fb4/fb070/modal-style-4.png 1152w", "/static/071d787ac55a8420707e72bed0520fb4/c3e47/modal-style-4.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">
  Structure and spacing measurements for a input modal | px / rem
    </Caption>
    <h3 {...{
      "id": "mobile"
    }}>{`Mobile`}</h3>
    <p>{`Modals should be full screen on mobile.`}</p>
    <div className="image--fixed">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.97282608695653%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAABuUlEQVQ4y2NgwAJ2794Npr28vDDkoqKiwPSnT58YSAL19fXIXEYoBoOOjg7SDFNTU2MC0YqKiqZaWlqngPiYpqbmUSA+o6SkZIqshigwZcoUZhCdmZkZdf78+f8gfPv27f8PHz78X11dHQmSW7lyJTPRBgIVg20vLy8PuX///v8HDx78evbs2c8PHz78nzBhQjBI7siRI8QbuHTpUrDikpKS0Lt37/4H4j9Pnjz5/e7du//d3d0hILl9+/YRb+C6deuYoS4M/fjx4/9v3779+f79+28Qu6urC2zg/v37iTdwzZo1YMVlZWWhIFcBk8ifz1++/n73/uP/TqiBe/eRYODmzZvBiquqqkJ//Pjx/+/fP3/+//78+8+3t/8n9HaCDTxxeA/xBk6cOBEcKampaSHHjx39f/zUuV/7bv7/ufXy//851ZPBkTJp5VniDQSmP7BiWTmFSC1N9f8qmob/+Z2n/Od0XfhfQCcCnGx4zCuJN1BMTIwJSpuLiopelJQQPy0tyn1aUoj9sqQIrzlITlKYi4mk3MLLy4tTToKVtJwHchmYFhcXZxAWFmb4//8/mA9ig8SQ1aADAKRVwoH+4kvyAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Modal on mobile",
          "title": "Modal on mobile",
          "src": "/static/405928c53a05210db1f6a7ad493b35aa/fb070/modal-style-3.png",
          "srcSet": ["/static/405928c53a05210db1f6a7ad493b35aa/d6747/modal-style-3.png 288w", "/static/405928c53a05210db1f6a7ad493b35aa/09548/modal-style-3.png 576w", "/static/405928c53a05210db1f6a7ad493b35aa/fb070/modal-style-3.png 1152w", "/static/405928c53a05210db1f6a7ad493b35aa/c3e47/modal-style-3.png 1472w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "loading": "lazy"
        }}></img>{`
    `}</span>
    </div>
    <Caption mdxType="Caption">Example of a modal on a mobile screen</Caption>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      